import React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import classNames from 'classnames'

// Content Components
import BusinessThreeCol from './BusinessThreeCol/BusinessThreeCol'
import BusinessUpClose from './BusinessUpClose/BusinessUpClose'
import CareersList from './CareersList/CareersList'
import Clients from './Clients/Clients'
import CommunityGrid from './CommunityGrid/CommunityGrid'
import ContactLocations from './ContactLocations/ContactLocations'
import ContactTwoCol from './ContactTwoCol/ContactTwoCol'
import CourseCovered from './CourseCovered/CourseCovered'
import CourseDates from './CourseDates/CourseDates'
import CourseHowItWorks from './CourseHowItWorks/CourseHowItWorks'
import CoursesTwoUp from './CoursesTwoUp/CoursesTwoUp'
// import EventbriteCarousel from './EventbriteCarousel/EventbriteCarousel'
import FacebookPixel from './FacebookPixel/FacebookPixel'
import FiftyFifty from './FiftyFifty/FiftyFifty'
import FiftyFiftyCollage from './FiftyFiftyCollage/FiftyFiftyCollage'
import FinancePlans from './FinancePlans/FinancePlans'
import Hero from './Hero/Hero'
import HeroBlocks from './HeroBlocks/HeroBlocks'
import InfoBlock from './InfoBlock/InfoBlock'
import InfoGrid from './InfoGrid/InfoGrid'
import LatestBlogPosts from './LatestBlogPosts/LatestBlogPosts'
import MirrorCTA from './MirrorCTA/MirrorCTA'
import PageFaqs from './Faqs/PageFaqs'
import PageHeroMinimal from './HeroMinimal/PageHeroMinimal'
import PageHubspotForm from './HubspotForm/PageHubspotForm'
import PageTestimonialCarousel from './TestimonialCarousel/PageTestimonialCarousel'
import PodcastList from './PodcastList/PodcastList'
import PreFooter from './PreFooter/PreFooter'
import TeamGrid from './TeamGrid/TeamGrid'
import VideoEmbed from './VideoEmbed/VideoEmbed'
import WhyCarousel from './WhyCarousel/WhyCarousel'
import VideoSideBySide from './VideoSideBySide/VideoSideBySide'
import Paragraph from './Paragraph/Paragraph'
import DropdownContent from './DropdownContent/DropdownContent'

// Generic
import ConditionalWrapper from '../shared/ConditionalWrapper/ConditionalWrapper'

// Make sure certain (heavier) components are loaded using `loadable`

const PageElements = ({ slices, location }) => {
  const contentComponents = {
    business_three_col: BusinessThreeCol,
    business_up_close: BusinessUpClose,
    careers_list: CareersList,
    clients: Clients,
    community_grid: CommunityGrid,
    contact_locations: ContactLocations,
    contact_two_col: ContactTwoCol,
    course_covered: CourseCovered,
    course_dates: CourseDates,
    course_how_it_works: CourseHowItWorks,
    'courses_two-up': CoursesTwoUp,
    // eventbrite_carousel: EventbriteCarousel,
    faqs: PageFaqs,
    fifty_fifty: FiftyFifty,
    fifty_fifty_collage: FiftyFiftyCollage,
    finance_plans: FinancePlans,
    hero: Hero,
    hero_blocks: HeroBlocks,
    hero_minimal: PageHeroMinimal,
    hubspot_form: PageHubspotForm,
    info_block: InfoBlock,
    info_grid: InfoGrid,
    latest_blog_posts: LatestBlogPosts,
    mirror_cta: MirrorCTA,
    podcast_list: PodcastList,
    'pre-footer': PreFooter,
    testimonial_carousel: PageTestimonialCarousel,
    team_grid: TeamGrid,
    video_embed: VideoEmbed,
    why_carousel: WhyCarousel,
    'video_side-by-side': VideoSideBySide,
    'paragraph': Paragraph,
    dropdowncontent: DropdownContent
  }

  return slices.map((slice) => {
    if (contentComponents[slice.slice_type]) {
      const Element = contentComponents[slice.slice_type]

      // Sometimes, we may have two Hero elements, one above the other. We
      // want to ensure the second one has a minus margin-top, to ensure it
      // sits flush with the first one.
      const isHero = slice.slice_type === 'hero'
      const isMultipleHeros = slices.filter((s) => s.slice_type === 'hero').length > 1
      let isSecondHeroStraightAfterFirst = false

      if (isHero && isMultipleHeros) {
        const heroIndex = slices.findIndex((s) => s.slice_type === 'hero')
        const thisHeroIndex = slices.findIndex((s) => s.id === slice.id)

        if (thisHeroIndex === heroIndex + 1) {
          isSecondHeroStraightAfterFirst = true
        }
      }

      // Conditional wrapper here, as we only want to add an extra element if
      // we need to. The extra element will show/hide the content based on the
      // `display` option, set in the CMS
      return (
        <ConditionalWrapper
          key={slice.id}
          condition={slice.primary?.display}
          wrapper={(children) => (
            <div
              className={classNames({
                'sr-only sm:not-sr-only': slice.primary.display === 'hidden-on-mobile',
                'sm:sr-only': slice.primary.display === 'hidden-on-tablet-desktop',
                'bg-primary': isSecondHeroStraightAfterFirst
              })}
            >
              {children}
            </div>
          )}
        >
          <Element
            data={slice}
            location={location}
            isSecondary={isSecondHeroStraightAfterFirst}
          />
        </ConditionalWrapper>
      )
    } else {
      console.log('could not find a slice for', JSON.stringify(slice, null, 2))
    }
    // Otherwise, no component found. Might need to add it to your PageQuery?
  })
}

PageElements.propTypes = {
  slices: PropTypes.array.isRequired
}

export default PageElements

/**
 * Simple React component to loop through the available components which are
 * allowed for each section of the page
 */
export const RenderPositionContentComponents = (props) => {
  const { slices, validComponents, location } = props

  const filteredSlices = slices.filter((slice) => {
    return validComponents.includes(slice.slice_type)
  })

  return (
    <PageElements
      slices={filteredSlices}
      location={location}
    />
  )
}

RenderPositionContentComponents.propTypes = {
  slices: PropTypes.array.isRequired,
  validComponents: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
}
