import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const CommunityGrid = (props) => {
  return (
    <section className='mb-16 relative overflow-hidden pt-16'>
      <div className='max-w-4xl mx-auto px-4 relative z-10'>
        <Heading
          className='h2 mb-12'
          {...props.data.primary.community_grid_title}
        />
        <div className='sm:pt-16 mb-6 space-y-8 sm:space-y-0 sm:columns-2 sm:column-gap-6'>
          {props.data.items.map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
                className='sm:inline-block sm:w-full sm:pb-8'
              >
                <div className={classNames('px-10 py-14 shadow-lg bg-white', { 'sm:-mt-16': (itemIndex === 0) })}>
                  <div className='w-48 sm:w-1/2 ml-10 mb-6 relative'>
                    <div className='w-12 absolute top-0 left-0 -ml-10 mt-10'>
                      <Image
                        {...item.community_grid_icon}
                        fallbackAlt={item.community_grid_header.text}
                      />
                    </div>
                    <Image
                      {...item.community_grid_illustration}
                      fallbackAlt={item.community_grid_header.text}
                    />
                  </div>
                  <div className='space-y-2'>
                    <Heading
                      className='h4'
                      {...item.community_grid_header}
                    />
                    <HtmlContent
                      html={item.community_grid_body.html}
                      className='text-lg c-prose'
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {props.data.primary.community_grid_cta_label.text && (
          <div className='text-center pt-12'>
            <Button
              {...props.data.primary.community_grid_cta_link}
              color={props.data.primary.cta_style}
              icon
            >
              {props.data.primary.community_grid_cta_label.text}
            </Button>
          </div>
        )}
      </div>
      <LogoSymbolRed className='hidden sm:block w-125 absolute right-0 top-0 -mr-32 mt-16' />
    </section>
  )
}

CommunityGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default CommunityGrid

export const query = graphql`
  fragment CommunityGrid on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCommunityGrid {
          id
          slice_type
          primary {
            display
            community_grid_title {
              text
              raw
            }
            community_grid_cta_label {
              text
            }
            community_grid_cta_link {
              ...Link
            }
            cta_style
          }
          items {
            community_grid_body {
              html
            }
            community_grid_header {
              text
              raw
            }
            community_grid_icon {
              ...Image
            }
            community_grid_illustration {
              ...Image
            }
          }
        }
      }
    }
  }
`
