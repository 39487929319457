import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import TwoUpItem from '../../shared/TwoUp/TwoUpItem'
import TwoUpWrapper from '../../shared/TwoUp/TwoUpWrapper'
import Heading from '../../shared/Heading/Heading'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const MirrorCTA = (props) => {
  if (props.data.primary) {
    const blocks = [
      {
        title: props.data.primary.mirror_cta_title_a,
        header: props.data.primary.mirror_cta_header_a,
        cta_label: props.data.primary.mirror_cta_cta_label_a,
        cta_link: props.data.primary.mirror_cta_cta_link_a,
        body: props.data.primary.mirror_cta_body_a
      },
      {
        title: props.data.primary.mirror_cta_title_b,
        header: props.data.primary.mirror_cta_header_b,
        cta_label: props.data.primary.mirror_cta_cta_label_b,
        cta_link: props.data.primary.mirror_cta_cta_link_b,
        body: props.data.primary.mirror_cta_body_b
      }
    ]
    return (
      <Container
        element='section'
        className='mb-16 sm:mb-0'
      >
        <TwoUpWrapper className='mt-10 sm:mt-20 md:mt-30'>
          {blocks.map((block, blockIndex) => {
            return (
              <TwoUpItem
                key={blockIndex}
                pullUp={false}
              >
                <p className='uppercase italic font-medium text-sm tracking-wide mb-4'>{block.header.text}</p>
                <LogoSymbolRed className='w-4 mb-8' />
                <Heading
                  className='h3 mb-4'
                  {...block.title}
                />
                <div className='mb-8'>
                  <HtmlContent
                    html={block.body.html}
                    className='c-prose'
                  />
                </div>
                <Button
                  {...block.cta_link}
                  color={block.cta_style}
                  size='small'
                  icon
                >
                  {block.cta_label.text}
                </Button>
              </TwoUpItem>
            )
          })}
        </TwoUpWrapper>
      </Container>
    )
  }

  return null
}

export default MirrorCTA

export const query = graphql`
  fragment MirrorCTA on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyMirrorCta {
          id
          slice_type
          primary {
            mirror_cta_title_a {
              text
              raw
            }
            mirror_cta_header_a {
              text
            }
            mirror_cta_cta_label_a {
              text
            }
            mirror_cta_cta_link_a {
              ...Link
            }
            mirror_cta_body_a {
              html
            }
            mirror_cta_title_b {
              text
              raw
            }
            mirror_cta_header_b {
              text
            }
            mirror_cta_cta_label_b {
              text
            }
            mirror_cta_cta_link_b {
              ...Link
            }
            mirror_cta_body_b {
              html
            }
          }
        }
      }
    }
  }
`
