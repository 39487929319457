import React from "react";
import { graphql } from "gatsby";
import DropdownContentItem from "./DropdownContentItem";
import Container from "../../shared/Container/Container";

const DropdownContent = (props) => {
  const items = props.data.items;

  return (
    <Container className='my-12'>
    <ul>
      {items.map((item) => {
        const heading = item.heading.text;
        const content = item.content.html;

        return (
          <DropdownContentItem
            heading={heading}
            content={content}
          />
        );
      })}
    </ul>
    </Container>
  );
};

export default DropdownContent;

export const query = graphql`
  fragment DropdownContent on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyDropdowncontent {
          id
          slice_type
          items {
            heading {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`;
