import React, { useState } from "react";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";
import ChevronDown from '../../../assets/img/chevron-down.svg';

export default function DropdownContentItem({ heading, content }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li>
      <h3
        onClick={() => setIsOpen(!isOpen)}
        className={`text-xl tracking-normal italic cursor-pointer flex flex-row items-center
        ${!isOpen ? 'mb-6' : 'mb-0'}`}
      >
        {heading}

        {!isOpen ? (
            <ChevronDown
                className={`h-2 ml-2 fill-current text-primary`}
            />
        ) : (
            <ChevronDown
                className={`h-2 ml-2 fill-current text-primary transform rotate-180`}
            />
        )}
      </h3>

      {isOpen && <HtmlContent className="c-prose mb-6 mt-4" html={content} />}
    </li>
  );
}
