import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";
import Container from "../../shared/Container/Container";

const Paragraph = (props) => {
  const contentHtml = props.data.primary.content.html;
  const spacing = props.data.primary.change_spacing || 12;
  const topSpacing = props.data.primary.top_spacing;
  const bottomSpacing = props.data.primary.bottom_spacing;
  const bothSpacing = topSpacing && bottomSpacing;

  return (
    <Container className={`
      ${bothSpacing ? `my-${spacing}` : ''}
      ${topSpacing && !bottomSpacing ? `mt-${spacing}` : ''}
      ${bottomSpacing && !topSpacing ? `mb-${spacing}` : ''}
    `}>
      <HtmlContent html={contentHtml} className='c-prose' />
    </Container>
  );
};

Paragraph.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Paragraph;

export const query = graphql`
  fragment Paragraph on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyParagraph {
          id
          slice_type
          primary {
            content {
              html
            }

            top_spacing
            bottom_spacing
            change_spacing
          }
        }
      }
    }
  }
`;
